import React from "react";
import Img from "react-image";

import "./ContactUs.css";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

export default function ContactUs() {
  return (
    <div id="contactanos" className="contact-block">
      <ContactInfo />
      <div className="map-container">
        <Map />
      </div>
    </div>
  );
}

function Map() {
  return (
    <div className="gmap">
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyD6diZKB97sXC0GPKqHIk8OadrTImqOFOE"
      >
        <GoogleMap
          id="map"
          center={{ lat: 18.3602677, lng: -66.0755482 }}
          zoom={20}
        />
      </LoadScript>
    </div>
  );
}

function ContactInfo() {
  return (
    <div className="contact-info">
      <h3>¡Contáctanos!</h3>
      <div className="phone-fax">
        <strong>Teléfonos:</strong> <a href="tel:787-708-1300">787-708-1300</a>{" "}
        <strong>Fax: </strong>787-708-1800
      </div>
      <div className="email">
        <strong>Correo electrónico:</strong>{" "}
        <a href="mailto:farmaciaesperanza@yahoo.com">
          farmaciaesperanza@yahoo.com
        </a>
      </div>
      <div className="hours">
        <strong>Horarios:</strong>
        <ul>
          <li>Lunes a Viernes de 7:30AM - 7:30PM</li>
          <li>Sábado de 8:00AM - 6:30PM</li>
          <li>Domingos - Cerrado</li>
        </ul>
      </div>
      <div className="social-media">
        <div className="app-store-container">
          <a href="https://www.facebook.com/FarmaciaEsperanzaPR">
            <Img className="app-store-icon" src="/assets/ic_fb.png" />
          </a>
          <a href="https://www.instagram.com/farmaciaesperanza_pr/">
            <Img className="app-store-icon" src="/assets/ic_insta.png" />
          </a>
        </div>
      </div>
    </div>
  );
}
