import React from "react";

import Service from "./Service";

export default function Services() {
	const vacBody = (
		<div>
			Tenemos el servicio de vacunación disponible para adultos y niños (mayores de 7 años). Ofrecemos la mayoría de las
			vacunas como influenza, pulmonía, culebrilla y tétano. Aceptamos la mayoría de los planes médicos y no se requiere
			receta para la mayoría de las vacunas. Se puede coordinar la visita o simplemente pasar por la farmacia para su
			conveniencia ¡Es rápido y sencillo!
			<br /> <br />
			Somos proveedores de la Vacuna para el Covid 19 (la vacuna no tiene ningún costo). ¡Puede comunicarse para
			coordinar su cita!
		</div>
	);

	return (
		<div id="servicios" className="services-block">
			<h1
				style={{
					alignSelf: "center",
					textAlign: "center",
					padding: "30px 10px 10px 10px",
				}}
			>
				Servicios
			</h1>
			<Service
				id={"entrega-al-hogar"}
				invert={false}
				url="/assets/fe-auto-farmacia.png"
				title="ENTREGAMOS A TU HOGAR DENTRO Y FUERA DEL ÁREA METROPOLITANA"
				body="Nuestro servicio de entrega a domicilio incluye medicamentos y también artículos de primera necesidad de nuestro Mini-Market. Sólo haz tu orden por teléfono o por correo electrónico y te la llevamos a la comodidad de tu hogar. Así de fácil."
			/>
			<Service
				id={"compounding"}
				invert={true}
				url="/assets/fe-compounding-2.jpg"
				title="¿QUÉ SIGNIFICA SER UNA FARMACIA CON SERVICIO DE “COMPOUNDING”?"
				body="Significa que somos una de las pocas farmacias en Puerto Rico especializadas en la preparación de medicamentos para personas con necesidades específicas que no 	 pueden satisfacerse con medicamentos disponibles en el mercado. Nuestro recetario cuenta con el equipo de laboratorio especializado para elaborar medicamentos a la medida de tus necesidades."
			/>
			<Service
				id={"recetario-automatizado"}
				invert={false}
				url="/assets/fe-pills-md.jpg"
				url1="/assets/fe-blister.jpg"
				title="RECETARIO AUTOMATIZADO"
			>
				<div>
					<ul>
						<li style={{ padding: "5px" }}>
							Empaque blister - Contamos con la tecnología de empaque “blister” para entregar los medicamentos de forma
							protegida y controlada, asegurando así la administración correcta de los medicamentos. Ideal para personas
							con múltiples medicamentos, personas de edad avanzada y/o cuidadores.
						</li>
						<li>
							Robot -&nbsp;Es un sistema automático de alta tecnología para dispensación de medicamentos. Una de sus
							ventajas es que agiliza el proceso y reduce la posibilidad de errores a la hora de despachar los
							medicamentos.
						</li>
					</ul>
				</div>
			</Service>
			<Service
				id={"cuidado-farmaceutico"}
				invert={true}
				url="/assets/fe-consultas.jpg"
				title="CUIDADO FARMACEÚTICO"
				body="Brindamos orientación al paciente, según sean sus necesidades. También proveemos apoyo a hogares de envejecientes, hospicios y hospitales."
			/>
			<Service
				id={"diabetes"}
				invert={false}
				url="/assets/fe-diabetes.jpg"
				title="PROVEEMOS EDUCACIÓN SOBRE DIABETES"
				body="Nuestra farmacéutica regente Mabel Febres está certificada como Educadora en Diabetes por la AACE (American Association of Clinical Endocrinologists) y por la APhA (American Pharmacists Association)."
			/>

			<Service id="vacunacion" invert={true} url="/assets/fe-vaccines.jpg" title="VACUNACIÓN" body={vacBody} />
		</div>
	);
}
