import React from "react";
import Img from "react-image";
import "./OurPeople.css";

export default function OurPeople() {
  return (
    <div className="page-block nuestra-gente-block">
      <h1 className="page-title">NUESTRA GENTE</h1>
      <Mabel />
      <div className="divider" style={{ margin: "25px 50px" }}></div>
      <Team />
    </div>
  );
}

function Mabel() {
  return (
    <div className="esperanza-block">
      <div className="esperanza-item">
        <div className="mabel-image">
          <Img
            style={{ width: "100%", height: "100%" }}
            src="/assets/fe-mabel.jpg"
          />
        </div>
      </div>
      <div className="esperanza-item mabel-info">
        <div className="mabel-info">
          <h4>MABEL FEBRES</h4>
          <div className="divider"></div>
          <div className="mabel-title">RPH, MS, FACA</div>
          <div className="mabel-description">
            <p>
              Con más de 18 años de exitosa trayectoria en compañías
              farmacéuticas como Ortho McNeill, Pfizer y Lilly del Caribe, Mabel
              se ha desempeñado como Farmacéutica Regente de la Farmacia
              Esperanza desde el 2015. Posee una Maestría en Manufactura de
              Productos Farmacéuticos y está certificada en “Compounding
              Pharmacy”, incluyendo las siguientes especialidades:
            </p>
            <ul>
              <li>Manejo de Dolor</li>
              <li>Dermatología</li>
              <li>Reemplazo Hormonal</li>
              <li>Veterinaria</li>
            </ul>
            <p>
              Mabel es Fellow del ACA (American College of Apothecaries) y
              miembro de IACP (International Academy of Compounding Pharmacists)
              y de A4M (American Academy of Antiaging Medicine). Además, está
              certificada como Educadora en Diabetes por la AACE (American
              Association of Clinical Endocrinologists) y por la APhA (American
              Pharmacists Association) y es farmacéutico inmunizador.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Team() {
  return (
    <div className="block esperanza-block team-info">
      <h3 className="team-title">NUESTRO PERSONAL TÉCNICO DE PRIMER ORDEN</h3>
      <h5 className="team-subtitle">
        NUESTRO ALTAMENTE CAPACITADO PERSONAL FARMACÉUTICO TE ESPERA PARA
        ATENDERTE CON EL TRATO PERSONALIZADO Y DE EXCELENCIA QUE TÚ MERECES.
      </h5>
      <div className="team-image">
        <Img
          style={{ width: "100%", height: "100%" }}
          src="/assets/fe-team-1.jpg"
        />
      </div>
    </div>
  );
}
