import React from "react";

import "./SendRx.css";
import Form from "../components/Form";
import Img from "react-image";

export default function SendRx() {
  return (
    <div className="page-block rx-block">
      <h2 className="page-title">ENTREGA TU RECETA</h2>
      {/* <div className="esperanza-block rx-text"></div> */}
      <div className="rx-type">
        <div className="doctor-block">
          <Img className="doctor-icon" src="/assets/fe-doctor.png" />
          <h3>Doctores</h3>
          <p>
            Le ofrecemos la alternativa al proveedor de salud de gestionar el
            envío de recetas electrónicas a la farmacia de predilección del
            paciente. Contamos con el servicio E-Prescribing. De no tener
            Surescripts, podrán enviar sus recetas a través de la sección de
            pacientes.{" "}
          </p>
          <Img width={"250px"} height={"65px"} src="/assets/surescripts.png" />
        </div>
        <div className="patient-block">
          <Img className="patient-icon" src="/assets/fe-patient.png" />
          <h3>Pacientes</h3>
          <p>
            Agiliza el despacho de tus medicamentos enviándonos tu receta por
            cualquiera de estas vías:
            <br />
            <br />
            <ul>
              <li>
                Applicación RefillRx - Baja la aplicación en tú teléfono móvil
                en los siguientes enlaces:
                <div className="app-store-container">
                  <a href="https://apps.apple.com/us/app/refillrx/id1051322965">
                    <Img
                      className="app-store-icon"
                      src="/assets/app-store.png"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.rx30.refillrx&hl=en">
                    <Img
                      className="app-store-icon"
                      src="/assets/google-play.png"
                    />
                  </a>
                </div>
              </li>
              <li>Fax: 787-708-1800</li>
              <li>Formulario web</li>
            </ul>
          </p>
          <Form />
        </div>
      </div>

      <div className="form-container"></div>
    </div>
  );
}
