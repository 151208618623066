// import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green, purple } from "@material-ui/core/colors";

export const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
    }
  }
})(Button);

export const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText("#309f48"),
    backgroundColor: "#309f48",
    "&:hover": {
      backgroundColor: "#309f48"
    }
  }
}))(Button);

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: green
  }
});

// export default function CustomizedButtons() {
//   const classes = useStyles();

//   return (
//     <div>
//       <ColorButton
//         variant="contained"
//         color="primary"
//         className={classes.margin}
//       >
//         Custom CSS
//       </ColorButton>
//       <ThemeProvider theme={theme}>
//         <Button variant="contained" color="primary" className={classes.margin}>
//           Theme Provider
//         </Button>
//       </ThemeProvider>
//       <BootstrapButton
//         variant="contained"
//         color="primary"
//         disableRipple
//         className={classes.margin}
//       >
//         Bootstrap
//       </BootstrapButton>
//     </div>
//   );
// }
