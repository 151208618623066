import React, { Component } from "react";
import PropTypes from "prop-types";
import "date-fns";

import { ColorButton } from "./RxButton";
import Button from "@material-ui/core/Button";
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { InputLabel, Input, FormControl } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";

const SUCCESS_MSG = "Su receta fue envíada exitosamente!";
const ERROR_MSG =
  "Sucedio un error enviando su receta, favor intentar nuevamente o llame al 787-708-1300";
const REQUIRED_MSG = "Favor completar todos los campos requeridos.";

const styles = (theme) => ({
  cssRoot: {
    color: theme.palette.getContrastText("#7d4a9c"),
    backgroundColor: "#7d4a9c",
    // fontSize: ''
  },
});

class App extends Component {
  constructor() {
    super();
    this.processing = false;
    this.state = {
      name: "",
      lastname: "",
      email: "",
      address: "",
      alergies: "",
      birthdate: null,
      phone: "   -   -    ",
      numberformat: "1320",
      files: {},
      open: false,
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  fileChangedHandler = (event, name) => {
    const files = this.state.files;
    files[name] = event.target.files;
    this.setState({ files });
    console.log(this.state.files);
  };

  uploadHandler = async () => {
    console.log(this.state.files);
    if (
      !this.state.name ||
      !this.state.lastname ||
      !this.state.email ||
      !this.state.phone ||
      !this.state.license ||
      !this.state.address ||
      !this.state.birthdate ||
      !this.state.files["receta"] ||
      !this.state.files["plan_medico"]
    ) {
      this.setState({ open: true, message: REQUIRED_MSG });
    } else {
      const formData = new FormData();
      formData.append("name", this.state.name);
      formData.append("lastname", this.state.lastname);
      formData.append("email", this.state.email);
      formData.append(
        "birthdate",
        this.state.birthdate.toISOString().split("T")[0]
      );
      formData.append("phone", this.state.phone);
      formData.append("license", this.state.license);
      formData.append("address", this.state.address);
      formData.append("allergies", this.state.allergies);
      for (const file of this.state.files["receta"]) {
        formData.append("files[]", file, file.name);
      }

      for (const file of this.state.files["plan_medico"]) {
        formData.append("files[]", file, file.name);
      }
      if (!this.processing) this.sendForm(formData);
    }
  };

  async sendForm(formData) {
    this.processing = true;
    try {
      const response = await axios.post(
        "https://esperanza-rx.herokuapp.com/sendMail",
        formData
      );
      console.log(response.status, response.status === 200);
      if (response.status === 200)
        this.setState({ open: true, message: SUCCESS_MSG });
    } catch (error) {
      this.setState({ open: true, message: ERROR_MSG });
    }
    this.processing = false;
  }

  handleFieldChange(event) {
    console.log(event.target.id);
    let stateData = {};
    stateData[event.target.id] = event.target.value;
    this.setState(stateData);
  }

  handleDateChange(date) {
    console.log("date", date);
    this.setState({ birthdate: new Date(date) });
  }

  render() {
    return (
      <div className="form">
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alerta!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <form autoComplete="on" style={{ width: "100%" }}>
          <div className="form-field-names">
            <TextField
              required
              id="name"
              label="Nombre"
              defaultValue=""
              value={this.state.name}
              onChange={this.handleFieldChange}
              margin="normal"
            />
            <TextField
              required
              id="lastname"
              label="Apellidos"
              defaultValue=""
              value={this.state.lastname}
              onChange={this.handleFieldChange}
              margin="normal"
            />
          </div>

          <div className="form-field-phone-email">
            <FormControl margin="normal">
              <InputLabel>Teléfono*</InputLabel>
              <Input
                value={this.state.phone}
                onChange={this.handleFieldChange}
                id="phone"
                required
                inputComponent={TextMask}
              />
            </FormControl>

            <TextField
              required
              id="email"
              label="Email"
              defaultValue=""
              value={this.state.email}
              onChange={this.handleFieldChange}
              margin="normal"
            />
          </div>

          <div className="form-field-address">
            <TextField
              fullWidth={true}
              required
              id="address"
              label="Dirección"
              defaultValue=""
              value={this.state.address}
              onChange={this.handleFieldChange}
              margin="normal"
            />
          </div>

          <div className="form-field-date">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha de nacimiento"
                required
                value={this.state.birthdate}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className="form-field-names">
            <TextField
              required
              type="number"
              id="license"
              label="# de Licencia"
              defaultValue=""
              value={this.state.license}
              onChange={this.handleFieldChange}
              margin="normal"
            />
            <TextField
              id="allergies"
              label="Alergias"
              defaultValue=""
              value={this.state.allergies}
              onChange={this.handleFieldChange}
              margin="normal"
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", padding: "8px" }}
          >
            <InputLabel>Imagén de Receta:</InputLabel>
            <div className="file-input-container">
              <input
                multiple
                accept="image/*"
                type="file"
                onChange={(e) => this.fileChangedHandler(e, "receta")}
              />
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", padding: "8px" }}
          >
            <InputLabel>Imagén de Plan Médico:</InputLabel>
            <div className="file-input-container">
              <input
                single
                accept="image/*"
                type="file"
                onChange={(e) => this.fileChangedHandler(e, "plan_medico")}
              />
            </div>
          </div>
          <div className="form-submit">
            <ColorButton
              variant={"raised"}
              color={"primary"}
              onClick={this.uploadHandler}
            >
              Enviar
            </ColorButton>
          </div>
        </form>
      </div>
    );
  }
}

export default App;

function TextMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
