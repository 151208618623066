import React from "react";

const styles = {
  minHeight: "500px",
};

export default function ManejoTerapias() {
  return (
    <div id="mtm" className={"esperanza-block"} style={styles}>
      <div className={"compounding-block"}>
        <div className="text">
          <h2 className="title">
            <span>MANEJO DE TERAPIAS DE MEDICAMENTOS (MTM)</span>
          </h2>
          <div>
            <div>
              <div>
                Nuestro programa de manejo de terapias de medicamentos está
                diseñado para maximizar la efectividad de las mismas. El
                programa es especialmente útil para pacientes con condiciones
                crónicas o con necesidad de medicamentos de alto costo e
                incluye:
              </div>
              <div>&nbsp;</div>
              <ul>
                <li>
                  Llamada de recordatorio al momento de que toque repetir tus
                  medicamentos.
                </li>
                <li>
                  Revisión comprensiva de tus medicamentos, incluyendo los
                  medicamentos recetados por tu médico y los que compras sin
                  receta, como vitaminas y suplementos herbarios.
                </li>
                <li>
                  Lista personal de medicamentos que te explicará cómo tomarlos
                  correctamente y te ayudará a monitorear tu tratamiento.
                </li>
                <li>
                  Evaluación de los riesgos de posibles interacciones entre
                  medicamentos y que no estés experimentando efectos
                  secundarios.
                </li>
                <li>
                  Recomendación de alternativas de medicamentos más
                  costo-efectivas.
                </li>
                <li>
                  Contacto con tus proveedores de salud cuando se identifica
                  algún problema con tus medicamentos.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
