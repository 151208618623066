import React from "react";
import Img from "react-image";

export default function Service(props) {
	let item1 = props.invert ? "item-1" : "item-2";
	let item2 = props.invert ? "item-2" : "item-1";

	let img1 = (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				flex: "1 1 100%",
			}}
		>
			<Img
				style={{
					display: "block",
					alignSelf: "center",
					maxHeight: "500px",
					objectFit: "contain",
				}}
				width="100%"
				src={props.url}
			/>
		</div>
	);

	let img2 = null;
	if (props.url1)
		img2 = (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flex: "1 1 100%",
				}}
			>
				<Img
					style={{
						display: "block",
						alignSelf: "center",
						maxHeight: "500px",
						objectFit: "contain",
					}}
					width="100%"
					src={props.url1}
				/>
			</div>
		);

	return (
		<div id={props.id} name={props.id} className="esperanza-service esperanza-block">
			<div className={"esperanza-item " + item1}>
				{img1}
				{img2}
			</div>
			<div className={"esperanza-item item-text " + item2}>
				<div style={{ padding: "20px" }}>
					<h3>{props.title}</h3>
					<div>{props.body}</div>
					<div>{props.children}</div>
				</div>
			</div>
		</div>
	);
}
