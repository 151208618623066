import React from "react";
import Img from "react-image";
import { Link } from "@material-ui/core";
import { Carousel } from "react-bootstrap";

export default function Introduction() {
  return (
    <div className={"esperanza-intro esperanza-block"}>
      <Slideshow />
    </div>
  );
}

function Slideshow() {
  return (
    <div style={{ height: "calc(100vh - 80px)", width: "100%" }}>
      <Carousel style={{ height: "100%", width: "100%" }}>
        <Carousel.Item
          style={{
            backgroundImage: 'url("/assets/fe-slide-1.png")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: "100%"
          }}
        >
          <Slide>
            <Carousel.Caption style={{ position: "initial" }}>
              <h1>
                <strong>BIENVENIDOS</strong>
              </h1>
            </Carousel.Caption>
          </Slide>
        </Carousel.Item>
        <Carousel.Item
          style={{
            backgroundImage: 'url("/assets/fe-slide-2.png")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: "100%"
          }}
        >
          <Slide>
            <Carousel.Caption style={{ position: "initial" }}>
              <h1>
                FARMACIA DE <strong>COMPOUNDING</strong>
              </h1>
            </Carousel.Caption>
          </Slide>
        </Carousel.Item>
        <Carousel.Item
          style={{
            backgroundImage: 'url("/assets/fe-slide-3.png")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: "100%"
          }}
        >
          <Slide>
            <Carousel.Caption style={{ position: "initial" }}>
              <h1>
                ENTREGA A <strong>DOMICILIO</strong>
              </h1>
            </Carousel.Caption>
          </Slide>
        </Carousel.Item>
        <Carousel.Item
          style={{
            backgroundImage: 'url("/assets/fe-slide-4.png")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "100%",
            width: "100%"
          }}
        >
          <Slide>
            <Carousel.Caption style={{ position: "initial" }}>
              <h1>
                <strong>VACUNACIÓN</strong>
              </h1>
            </Carousel.Caption>
          </Slide>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

function Slide(props) {
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignSelf: "flex-end",
          backgroundColor: "#7741a8cc",
          height: "30%",
          width: "100%"
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
