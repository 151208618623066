import React from "react";

import Img from "react-image";
import Menu from "./Menu";
import "./AppBar.css";

export default function AppBar() {
  return (
    <div className="app-bar">
      <Img></Img>

      <Menu></Menu>
    </div>
  );
}
