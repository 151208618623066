import React from "react";
import "./FarmaciaPam.css";

export default function FarmaciaPam() {
  return (
    <div id="pam" className={"esperanza-block"}>
      <div className={"pam-block compounding-block"}>
        <div className="text">
          <h2>
            SOMOS UNA FARMACIA PAM ¡5 ESTRELLAS! (Programa de Adherencia de
            Medicamentos)
          </h2>
          <div>
            ¡Somos una farmacia PAM! Nuestro programa de adherencia de
            medicamentos está diseñado para asegurar el cumplimiento con las
            terapias asignadas por su médico y asi garantizar un buen control de
            la condición del paciente. El programa es especialmente útil para
            pacientes con condiciones crónicas que incluyen: diabetes,
            hipertensión y colesterol alto, entre otras.
          </div>
          <ul>
            <li>Realizamos llamadas de recordatorio para sus medicamentos.</li>
            <li>
              Sincronizamos la terapia de medicamentos para facilitar el
              cumplimiento
            </li>
            <li>
              Hacemos entrega al hogar, de no poder llegar a la farmacia a
              recoger los medicamentos.
            </li>
          </ul>
        </div>
        <div className="images">
          <img
            style={{
              display: "block",
              alignSelf: "center",
              maxHeight: "500px",
              width: "100%",
              objectFit: "contain",
            }}
            width="100%"
            src={"/assets/fe-call.jpg"}
          />
        </div>
      </div>
    </div>
  );
}
